@font-face {
  font-family: "Gobold-Regular";
  src: url("./assets/fonts/Gobold-Regular.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "BebasNeue-Regular";
  src: url("./assets/fonts/BebasNeue-Regular.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "DINPro-CondMedium";
  src: url("./assets/fonts/DINPro-CondMedium.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald-Regular.ttf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald-Regular.ttf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald-Regular.ttf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Inria Sans";
  src: url("./assets/fonts/InriaSans-Light.ttf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Grotesque";
  src: url("./assets/fonts/Grotesque-MT-Std-Light_21647.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "Grotesque";
  src: url("./assets/fonts/Grotesque-MT-Std-Light_21647.ttf") format("ttf");
  font-style: normal;
}

.App {
  text-align: center;
  background-color: white;
  height: 100vh;
}

.section-sub-title {
  font-size: 28px;
  color: black;
  font-weight: bold;
  font-family: "BebasNeue-Regular";
}

.icon-show {
  position: absolute;
  bottom: 80px;
}

.App-header {
  background-color: "red";
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.nav-list-container {
  text-align: center;
  flex: 1;
  padding-left: 0px;
}

.section-title-container-extra {
  flex: 0.2;
  justify-items: flex-start;
  justify-content: flex-start;
  text-align: start;
  margin-left: 7.5vw;
  flex-direction: row;
}

.App-link {
  color: #61dafb;
}

a {
  text-decoration: none;
}

.pattern {
  background-color: #e5e5f7;
  opacity: 1;
  background-size: 10px 10px;
  /*https://css-stripe-generator.firebaseapp.com/*/
  background-size: auto auto;
  background-color: rgba(193, 28, 33, 1);
  background-image: repeating-linear-gradient(
    168deg,
    transparent,
    transparent 7px,
    rgba(214, 62, 59, 1) 7px,
    rgba(214, 62, 59, 1) 8px
  );
}

@media (min-width: 851px) {
  .footer-text-web{
    color: white;
    font-size: 1rem;
    font-family: "Inria Sans";
    margin-bottom: 0px;
  }
  .footer-text-mobile{
    display: none;
  }
  ul li {
    list-style-type: none;
    display: inline-block;

    font-weight: 480;
  }
  .cover {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: row nowrap;
  }
  .coverLogo {
    margin-top: 35vh;
    width: 400px;
  }
  .nav-list {
    list-style-type: none;
    display: inline-block;
  }
  .nav-social-icons {
    position: absolute;
    right: 1vw;
    top: 25px;
    z-index: 1000;
  }
  .nav-hamburger {
    display: none;
  }
  .cover-gray {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 500px;
  }
  .cardProyectoMobile{
    display:none;
  }
  .vermas-container {
    flex: 0.1;
    margin-bottom: 10px;
  }
  .presentation-text {
    flex: 0.6;
    padding-top: 50px;
    width: 50%;
    font-weight: 450;
    color: #c50000;
    text-align: justify;
    font-family: "Inria Sans";
    font-size: 1.4rem;
  }
  .presentation-text-mobile {
    display: none;
  }

  .presentation-single-text {
    flex: 0.6;
    align-self: center;
    width: 50%;
    font-weight: 100;
    color: #fff;
    text-align: justify;
    font-family: "Inria Sans";
  }

  .presentation-single-text-mobile {
    display: none;
  }

  .imagesAgenciaMobile {
    display: none;
  }
  .hacemos-cards-container {
    flex: 1;
    justify-content: center;
    display: flex;
    width: 100%;

    flex-direction: row;
    padding: 0 !important;
  }

  .video-cards-container {
    flex: 1;
    justify-content: center;
    display: flex;
    background-color: #c50000;
    flex-direction: row;
    padding-top: 200 !important;
  }

  .hacemos-midle-card {
    flex: 1;
    background-color: #f1f0ef;
    text-align: left;
    height: 100%;
    cursor: pointer;
    min-height: 320px;
  }
  .hacemos-midle-card-list {
    display: none;
  }
  .hacemos-card-title {
    transition: 0.5s;
    /* vendorless fallback */
    font-size: 2rem;
    font-family: "Oswald";
    padding-left: 10%;
    color: #d6d5d3;
    font-weight: 300;
    padding-top: 30px;
    position: relative;
    z-index: 1000;
  }

  .hacemos-card-text {
    font-size: 1.2rem;
    margin-top: 8%;
    font-family: "Inria Sans";
    padding-left: 10%;
    color: "black";
    font-weight: 500;
    font-weight: bold;
    margin-bottom: 2vh;
    padding-bottom: 20%;
    position: relative;
    z-index: 1000;
    text-align: left;
  }


  .blue-project-bar {
    transition: 0.3s;
    /* vendorless fallback */
    background-color: #3ebfeb;
    width: 50px;
    height: 5px;
    border-radius: 10px;
    margin-left: 10%;
    padding-left: 10%;
  }
  .purple-bar {
    transition: 0.3s;
    /* vendorless fallback */
    background-color: #ec92ba;
    width: 50px;
    height: 5px;
    border-radius: 10px;
    position: relative;
    z-index: 1000;
    margin-left: 10%;
  }
  .cover-white-no-resize {
    height: 100%;
    background-size: auto auto;
    background-color: rgba(255, 255, 255, 1);
    background-image: repeating-linear-gradient(
      160deg,
      transparent,
      transparent 7px,
      rgba(248, 248, 249, 1) 7px,
      rgba(248, 248, 249, 1) 9px
    );
  }
  .cover-white {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    opacity: 0.81;

    /*https://css-stripe-generator.firebaseapp.com/*/
    background-size: auto auto;
    background-color: rgb(255, 255, 255);
    background-image: repeating-linear-gradient(
      168deg,
      transparent,
      transparent 7px,
      #f1f0ef 7px,
      #f1f0ef 8px
    );
  }

  .cover-red {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    opacity: 1;

    background-size: 10px 10px;
    /*https://css-stripe-generator.firebaseapp.com/*/
    background-size: auto auto;
    background-color: rgba(193, 28, 33, 1);
    background-image: repeating-linear-gradient(
      168deg,
      transparent,
      transparent 7px,
      rgba(214, 62, 59, 1) 7px,
      rgba(214, 62, 59, 1) 8px
    );
  }
  .menu-mobile {
    display: none;
  }
  .nav-black {
    background-color: #1d1d1b;
    height: 12%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 99;
    top: 0;
  }
  .nav-black-mobile {
    display: none;
  }
  .carousel-hacemos {
    display: none;
  }
  .carousel-proyectos {
    display: none;
  }
  .section-title-container {
    flex: 0.2;
    justify-items: flex-start;
    justify-content: flex-start;
    text-align: start;
    padding-left: 2rem;
  }
  .section-title-container-extra {
    flex: 0.2;
    justify-items: flex-start;
    justify-content: flex-start;
    text-align: start;
  }
  .footer {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: "center";
    flex-direction: "row";
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #d32027;
    height: 100px;
  }

}

@media (max-width: 850px) {

  
  .presentation-text {
    display:none;
  }
  .presentation-text-mobile {


    flex: 0.6;
    padding-top: 50px;
    width: 80%;
    font-weight: 450;
    color: #c50000;
    text-align: left;
    font-family: "Inria Sans";
    font-size: 1.0rem;
  }


  .footer {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: "center";
    flex-direction: "row";
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #d32027;
    height: 100px;
  }
  .css-sm-bugfix {
    width: 100%;
    justify-content: center;
    align-content: center;
    display: flex;
  }
  ul .mo {
    list-style-type: none;
    padding-right: 25px;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    padding-left: 22px;
    height: 100%;
    border: 1px solid white;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .cover {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: row nowrap;
  }
  .coverLogo {
    margin-top: 40vh;
    width: 50vw;
  }
  .nav-list {
    display: none;
  }
  .nav-social-icons {
    display: none;
  }
  .hamburguer {
    margin-right: 5%;
  }
  .cover-gray {
    min-height: 300px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .presentation-single-text {
    display: none;
  }

  .imagesAgencia {
    display: none;
  }
  .presentation-single-text-mobile {
    flex: 0.6;
    align-self: center;
    width: 70%;
    font-weight: 100;
    color: #fff;
    text-align: justify;
    font-family: "Inria Sans";
  }
  .vermas-container {
    flex: 0.3;
    margin-bottom: 10px;
  }
  .menu-mobile {
    position: fixed;
    /* Stay in place */
    z-index: 999999999;
    /* Sit on top */
    right: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100vh;
    /* Full height */
    /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 1);
  }
  .nav-black {
    display: none;
  }

  .sliderProyectosWeb {
    display: none;
  }
  .nav-black-mobile {
    background-color: #1d1d1b;
    height: 12%;
    display: flex;
    justify-content: flex-end;

    align-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    margin-right: 40px;
  }
  .menu-list-mobile {
    font-size: 1.5rem;
    text-align: left;
  }
  .menu-list-mobile li {
    margin-bottom: 15px;
    padding-top: 15px;
  }
  .purple-bar {
    background-color: #ec92ba;
    width: 50px;
    height: 5px;
    border-radius: 10px;
    margin: auto;
  }
  .blue-project-bar {
    background-color: #3ebfeb;
    width: 50px;
    height: 5px;
    border-radius: 10px;
    margin: auto;
    padding-left: 10%;
  }
  .carousel-hacemos {
    width: 100%;
    z-index: 1;
  }
  .carousel-proyectos {
    height: 400px;
  }
  .hacemos-cards-container {
    display: none;
  }
  .cover-white {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-size: auto auto;
    background-color: rgba(255, 255, 255, 1);
    background-image: repeating-linear-gradient(
      160deg,
      transparent,
      transparent 7px,
      rgba(248, 248, 249, 1) 7px,
      rgba(248, 248, 249, 1) 9px
    );
  }

  .cover-red {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    opacity: 1;
    background-size: 10px 10px;
    /*https://css-stripe-generator.firebaseapp.com/*/
    background-size: auto auto;
    background-color: rgba(193, 28, 33, 1);
    background-image: repeating-linear-gradient(
      168deg,
      transparent,
      transparent 7px,
      rgba(214, 62, 59, 1) 7px,
      rgba(214, 62, 59, 1) 8px
    );
  }

  .cover-pink {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    background-size: auto auto;
    background-color: rgba(240, 153, 190, 1);
    background-image: repeating-linear-gradient(
      160deg,
      transparent,
      transparent 7px,
      rgba(227, 144, 180, 1) 7px,
      rgba(227, 144, 180, 1) 9px
    );
  }


  .cover-white-no-resize {
    height: 100%;
    background-size: auto auto;
    background-color: rgba(255, 255, 255, 1);
    background-image: repeating-linear-gradient(
      160deg,
      transparent,
      transparent 7px,
      rgba(248, 248, 249, 1) 7px,
      rgba(248, 248, 249, 1) 9px
    );
  }
  .hacemos-midle-card {
    width: 100%;
    background-color: #f1f0ef;
    text-align: center;
    height: 300px;
    padding-top: 2%;
  }
  .hacemos-midle-card-list {
    display: none;
  }
  .hacemos-card {
    background-color: #f1f0ef;
    text-align: center;
    height: 300px;
    padding-top: 2%;
  }
  .hacemos-card-list {
    background-color: #f1f0ef;
    text-align: left;
    height: 300px;
    padding-top: 2%;
  }
  .hacemos-card-title {
    font-size: 2.5rem;

    font-family: "Gobold-Regular";
    color: #d6d5d3;
    font-weight: 300;
  }

  .hacemos-card-text-content {
    transition: all 0.4s ease-in-out;
  }

  .hacemos-card-text {
    font-size: 1.2rem;
    margin-top: 2%;
    font-family: "Inria Sans";
    padding-left: 2%;
    margin-left: 8%;
    color: "#212529";
    font-weight: 500;
    font-weight: bold;
    position: relative;
    z-index: 1000;
    text-align: left;
  }

  .section-title-container {
    justify-items: center;
    justify-content: center;
    text-align: start;
    padding-left: 1rem;
  }
}

.coverLogoContainer {
  flex: 1;
  align-content: center;
  align-items: center;
}

.men {
  font-family: "Oswald";
  font-size: 1.3rem;
  padding-left: 25px;
  padding-right: 25px;
}

.gray {
  background-color: #f1f0ef;
}

.white {
  background-color: white;
}

.vermas-button {
  background-color: #fcd96b;
  border: none;
  color: #c50000;
  padding: 5px 50px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 550;
  font-family: "Inria Sans";
}

.din {
  font-family: "DINPro-CondMedium";
}

.gob {
  font-family: "Gobold-Regular";
}

.beb {
  font-family: "BebasNeue-Regular";
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.underline-menu-yel:after {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  opacity: 0;
  background: #f7d97c;
  transition: width 0.4s ease 0s, left 0.4s ease 0s;
  width: 0px;
}

.underline-menu-yel:hover:after {
  width: 100%;
  left: 0;
  opacity: 1;
  height: 2px;
}

.underline-menu-pink:after {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  opacity: 0;
  background: #e097b9;
  transition: width 0.4s ease 0s, left 0.4s ease 0s;
  width: 0px;
}

.underline-menu-pink:hover:after {
  width: 100%;
  left: 0;
  opacity: 1;
  height: 2px;
}

.underline-menu-blue:after {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  opacity: 0;
  background: #6abfe9;
  transition: width 0.4s ease 0s, left 0.4s ease 0s;
  width: 0px;
}

.underline-menu-blue:hover:after {
  width: 100%;
  left: 0;
  opacity: 1;
  height: 2px;
}

.underline-menu-orange:after {
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  opacity: 0;
  background: #e3903e;
  transition: width 0.4s ease 0s, left 0.4s ease 0s;
  width: 0px;
}

.underline-menu-orange:hover:after {
  width: 100%;
  left: 0;
  opacity: 1;
  height: 2px;
}

.underline-menu {
  bottom: 0;
  content: "";
  display: block;
  background: #e3903e;
  transition: width 0.4s ease 0s, left 0.4s ease 0s;
  width: 100%;
  left: 0;
  opacity: 1;
  height: 2px;
}

/*HACEMOS HOME */

.imgmobmain {
  width: 80px;
  margin: 0;
  z-index: 99999;
  position: absolute;
  top: 50%;
  left: 10%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.imgmob {
  width: 120px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.section-title {
  font-size: 1.8rem;

  margin: 0px;
  font-family: "Oswald";
}

.upper {
  text-transform: uppercase;
}

.section-title-no-space {
  opacity: 0;
  transition: 0.2s;
  font-size: 35px;
  letter-spacing: 2px;
  margin: 0px;
  font-family: "Oswald";
}

.card-per {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  /* cursor: pointer; */
}

.card-per:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.card-per-reverse {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  /* cursor: pointer; */
}

.card-per-reverse:hover {
  transform: scale(0.93);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.card-per-reverse .bar-fad .bar-color-fad {
  transition: 0.2s;
}

.card-per-reverse:hover .bar-fad .bar-color-fad {
  transition: 0.2s;
}

.card-per-reverse:hover .bar-fad .section-title-no-space {
  transition: 0.2s;
  opacity: 1;
}

.hoverFX {
  transition: 0.5s;
  /* vendorless fallback */
}

.hoverFX:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.hoverFX:hover .hacemos-card-title {
  color: black;
}

.hoverFX:hover .purple-bar {
  width: 100px;
  transition: 0.3s;
  /* vendorless fallback */
}

.card-per h3 {
  font-weight: 600;
}

@media (max-width: 990px) {
  .card-per {
    margin-top: 20px;
  }
}

#contentC {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  /* Need a specific value to work */
}

.draw-border {
  transition: all 0.12s 0.0833333333s;
  position: relative;
  z-index: 0;
}

.draw-border::before,
.draw-border::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  /*animation border*/

  opacity: 1;
  background-size: auto auto;
  background-color: #f1f0ef;
  background-image: repeating-linear-gradient(
    168deg,
    transparent,
    transparent 7px,
    rgb(255, 255, 255) 7px,
    rgb(255, 255, 255) 8px
  );
}

.draw-border:hover::before,
.draw-border:hover::after {
  border-color: #ec92ba;
  transition: all 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.draw-border:hover::before {
  transition-delay: 0s, 0s, 0.25s;
  z-index: 0;
}

.draw-border:hover::after {
  transition-delay: 0s, 0.25s, 0s;
  z-index: 0;
}

.draw-border-blue {
  transition: all 0.12s 0.0833333333s;
  position: relative;
}

.draw-border-blue::before,
.draw-border-blue::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  /*animation border*/

  opacity: 0.5;
  background-size: auto auto;
  background-color: rgba(0, 122, 166, 1);
  background-image: repeating-linear-gradient(
    165deg,
    transparent,
    transparent 7px,
    rgba(20, 149, 194, 1) 7px,
    rgba(20, 149, 194, 1) 8px
  );
}

.draw-border-blue:hover::before,
.draw-border-blue:hover::after {
  border-color: #000000;
  transition: all 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}

.draw-border-blue:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}

.draw-border-blue:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}

.draw-border-yellow {
  transition: all 0.12s 0.0833333333s;
  position: relative;
}

.draw-border-yellow::before,
.draw-border-yellow::after {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  /*animation border*/

  opacity: 0.5;
  background-size: auto auto;
  background-color: rgba(247, 217, 124, 1);
  background-image: repeating-linear-gradient(
    165deg,
    transparent,
    transparent 7px,
    rgb(241, 231, 199) 7px,
    rgb(241, 231, 199) 8px
  );
}

.draw-border-yellow:hover::before,
.draw-border-yellow:hover::after {
  border-color: #000000;
  transition: all 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}

.draw-border-yellow:hover::before {
  transition-delay: 0s, 0s, 0.25s;
}

.draw-border-yellow:hover::after {
  transition-delay: 0s, 0.25s, 0s;
}

.icon-scroll,
.icon-scroll:before {
  position: absolute;
  left: 50%;
}

.icon-scroll {
  width: 40px;
  height: 70px;
  margin-left: -20px;
  top: 50%;
  margin-top: -35px;
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 25px;
}

.icon-scroll:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}

@-moz-keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}

@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}

@-o-keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}

@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}

.draw-border-blue {
  transition: all 0.25s 0.0833333333s;
  position: relative;
}

.control-hover::before,
.control-hover::after .draw-border-blue {
  border: 0 solid transparent;
  box-sizing: border-box;
  content: "";
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
}

.control-hover::before .draw-border-blue {
  border-bottom-width: 4px;
  border-left-width: 4px;
}

.control-hover::after .draw-border-blue {
  border-top-width: 4px;
  border-right-width: 4px;
}

.control-hover:hover .color-ov {
  transition: all 0.5s;
  background-color: #63aed4;
}

.control-hover:hover::before,
.control-hover:hover::after .draw-border-blue {
  border-color: #63aed4;
  transition: all 0s, width 0.25s, height 0.25s;
  width: 100%;
  height: 100%;
}

.control-hover:hover::before .draw-border-blue {
  transition-delay: 0s, 0s, 0.25s;
}

.control-hover:hover::after .draw-border-blue {
  transition-delay: 0s, 0.25s, 0s;
}

.button-enviar {
  font-family: "Gobold-Regular";
  font-size: 1.3rem;
  margin-top: 40px;
  letter-spacing: 1px;
  background: none;
  color: white;
  position: relative;
  outline: none;
  border: none;
  height: 50px;
  width: 190px;
  z-index: 2;
  transition: 0.01s 0.23s ease-out all;
  overflow: hidden;
}

.button-enviar:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 55%;
  z-index: -1;
  transition: 0.3s ease-in all;
}

.button-enviar:after {
  content: "";
  position: absolute;
  left: -5%;
  top: 5%;
  height: 90%;
  width: 5%;
  background: white;
  z-index: -1;
  transition: 0.4s 0.02s ease-in all;
}

.button-enviar:hover {
  cursor: pointer;
  color: transparent;
}

.button-enviar:hover:before {
  left: 100%;
  width: 25%;
}

.button-enviar:hover:after {
  left: 100%;
  width: 70%;
}

.button-enviar:hover .icon-right.after:after {
  left: -80px;
  color: white;
  transition: 0.2s 0.2s ease all;
}

.button-enviar:hover .icon-right.after:before {
  left: -104px;
  top: 14px;
  opacity: 0.2;
  color: white;
}

.icon-right {
  position: absolute;
  top: 0;
  right: 0;
}

.icon-right:after {
  font-family: "FontAwesome";
  content: "→";
  font-size: 24px;
  display: inline-block;
  position: relative;
  top: 26px;
  transform: translate3D(0, -50%, 0);
}

.icon-right.after:after {
  left: -250px;
  color: black;
  transition: 0.15s 0.25s ease left, 0.5s 0.05s ease color;
}

.icon-right.after:before {
  content: "Enviar";
  position: absolute;
  left: -230px;
  top: 14px;
  opacity: 0;
  transition: 0.2s ease-in all;
}

.signature {
  position: fixed;
  font-family: sans-serif;
  font-weight: 100;
  bottom: 10px;
  left: 0;
  letter-spacing: 4px;
  font-size: 10px;
  width: 100vw;
  text-align: center;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 99;
}

.slick-next:before {
  color: grey !important;
}
.slick-prev:before {
  color: grey !important;
}

.imageHover {
  transition: transform 0.2s; /* Animation */
  width: 200px;
  height: 200px;
  margin: 0 auto;
  cursor: nesw-resize;
}

.imageHover:hover {
  transform: scale(
    1.5
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  z-index: 9999;
  animation: fronte 0.1s linear forwards;
  padding: 0;
  margin: 0;
}

@keyframes fronte {
  from {
    z-index: 0;
    transform: scale(1);
  }
  to {
    z-index: 4;
    transform: scale(1.04);
  }
}

.row > * {
  padding: 0;
}

.row {
  --bs-gutter-x: 0;
}

@media (max-width: 850px) {

  .footer-text-web{
    display: none;
  }
  .footer-text-mobile{
    color: white;
    font-size: 1rem;
    font-family: "Inria Sans";
    margin-bottom: 0px;
  }
  .css-sm-bugfix-2 {
    width: 80vw !important;
    min-height: 80vw;
    height: 0;
  }

  .css-sm-bugfix-2-allways {
    width: 100vw !important;
    min-height: 120vw !important;
    height: 0;
  }
}

.css-sm-bugfix-2-allways {
  width: 100vw !important;
  min-height: 47vw;
  height: 0;
}



.project-mobile-card-title{
  position:absolute;
  left: 10px;
  bottom:0px;
  color:white;
  font-size: 1rem;
  font-family:"Oswald"
}

.hacemos-m-card {
  flex: 1;
  background-color: #f1f0ef;
  text-align: left;
width: 90vw;
  cursor: pointer;
  min-height: 170px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.hacemos-m-card-title {
  transition: 0.5s;
  /* vendorless fallback */
  font-size: 2rem;
  font-family: "Oswald";
  padding-left: 10%;
  color: #d6d5d3;
  font-weight: 300;
  padding-top: 30px;
  position: relative;
  z-index: 1000;
}

.hacemos-m-card-text {
  font-size: 1rem;
  margin-top: 8%;
  font-family: "Inria Sans";
  padding-left: 10%;
  color: "black";
  padding-bottom: 10%;
  position: relative;
  z-index: 1000;
  text-align: left;
}

.purple-bar-m {
  transition: 0.3s;
  /* vendorless fallback */
  background-color: #ec92ba;
  width: 50px;
  height: 5px;
  border-radius: 10px;
  position: relative;
  z-index: 1000;
  margin-left: 10%;
}