body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  max-width: 100%;
  overflow: hidden;
}


.grecaptcha-badge { 
  visibility: hidden;
}


@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.hoverRadio:hover{
  zoom:1.2
}
.marquee {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: marquee 22s linear infinite;
}
.marquee p {
  display: inline-block;
}

@media (max-width: 850px) {
  .hideMobile{
    display: none !important;
  }
  .marquee-container{
    width: 100%;
    height: 50px;
    background-color: #36C4ED !important;
    font-size: 1rem;
    text-align: center;
    font-family: "Gobold-Regular";
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    color: white;
    padding: 0;
    top:-8;
    position:relative
  }
}

@media (min-width: 850px) {
  .hideWeb{
    display:none !important
  }
  .marquee-container{
    width: 100%;
    height: 90;
    background-color: #36C4ED !important;
    font-size: 1.5rem;
    text-align: center;
    font-family: "Gobold-Regular";
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    color: white;
    padding: 0;
    top:-8;
    position:relative
  }
  }